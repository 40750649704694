<template>
    <div class="row child-component">
        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
            <div class="d-block row col-lg-8 mx-auto">
                <div class="note-section mb-3">
                    <label>Note <span style="color:red">*</span></label>
                    <p>ATTN: Before you start this process, make sure you have an invoice in order to submit a Remittance Name/Address Change or a bank letter (invoice optional) for a Banking Info change. For a change in ownership, you will be required to add a W9.</p>
                </div>
                <div class="fieldborder">
                    <label>Employee Phone Number </label>
                    <the-mask v-if="this.userPhone" type="text" @focus="ResetDirty('EmployeePhoneNumber')"
                              @blur="SetDirty('EmployeePhoneNumber')" :tokens="{ '#': { pattern: /\d/ } }"
                              :mask="['##', '1-###-###-####']" style="margin-top:1px;" class="form-control" autocomplete="off"
                              :maxlength="14" v-model="MediaVendorAmend.EmployeePhoneNumber" placeholder="Employee Phone Number"
                              disabled></the-mask>

                    <b-form-input v-else placeholder="N/A" disabled></b-form-input>

                    <div class="error-message-format"
                         v-if="$v.MediaVendorAmend.EmployeePhoneNumber.$dirty && !$v.MediaVendorAmend.EmployeePhoneNumber.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder">
                    <label>AGENCY </label>
                    <OmniSelect placeholder="Select Agency" name="Media" :showLabel="false"  label="Select Media"
                                :options="AgencyList" v-model="MediaVendorAmend.EmployeeAgency" />
                </div>

                    <div class="fieldborder">
                        <OmniSelect placeholder="Select System" name="System" :showLabel="true"
                                    label="System" v-model="MediaVendorAmend.VendorTypeSystem" :options="VendorSystemList" />
                    </div>
                    <div class="fieldborder mt-n3">
                        <label>
                        <span class="lbltxt">
                            Media
                        </span>
                        <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9"
                            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            v-b-tooltip.hover.v-dark.right.html="' Please note, if you select a digital vendor, it will be changed across all media types'
                                ">
                            <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path
                                    d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                                    id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                            </g>
                        </svg></label>
                        <OmniSelect placeholder="Select Media"  name="Media" :showLabel="false" :disabled="!MediaVendorAmend.VendorTypeSystem"
                                    v-model="MediaVendorAmend.VendorTypeMedia" :options="VendorMediaList" />
                    </div>
                    <div class="fieldborder">
                        <OmniSelect placeholder="Select Code/Name" name="Code/Name" :showLabel="true" :disabled="!MediaVendorAmend.VendorTypeMedia"
                                    label="Code/Name" v-model="MediaVendorAmend.VendorTypeCode" :options="VendorCodeList" />
                      </div>
                    <div class="fieldborder">
                        <label>What is changing</label>
                        <div class="d-flex">
                            <b-form-group class="mx-2">
                                <b-form-checkbox v-model="MediaVendorAmend.IsRemittanceNameAddressChanged"  :value="true" label="" inline>
                                    Remittance Name/Address
                                </b-form-checkbox>
                                <b-form-checkbox v-model="MediaVendorAmend.IsBankingInfoChanged" :value="true" label="" inline>
                                    Banking Info
                                </b-form-checkbox>
                            </b-form-group>
                        </div>
                    </div>
                <div class="fieldborder">
                    <b-form-group label="Is this Request for a Media Rep?">
                        <b-form-radio-group class="mx-2" id="media-rep" v-model="MediaVendorAmend.IsMediaRepRequest" name="media-rep">
                            <b-form-radio value="true">Yes</b-form-radio>
                            <b-form-radio value="false">No</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
                </div>
                <!-- <div class="d-block row col-lg-8 offset-lg-2 mt-3 agree-checkbox">
        <b-form-checkbox value="true" v-model="MediaVendorAmend.Attestation">By checking this box, the requestor is confirming that he/she/they has conducted all acceptable measures of due diligence to ensure the propriety of the vendor and confirm that the information contained on the form is accuraate to the best of their knowledge</b-form-checkbox>
    </div> -->
            </div>
        <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Next()" :disabled="this.$v.$invalid">
                NEXT
            </button>
        </div>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import service from "../../../services/Dropdowns";
import { required, minLength, requiredIf, maxLength, numeric } from "vuelidate/lib/validators";
import { TheMask } from 'vue-the-mask';
import authenticateAdUser from "../../../mixins/aduserauthorization";
export default {
    mixins: [authenticateAdUser],
    data() {
        return {
            userPhone: null,
            AgencyList: [],
            SelectedNETList: [],
            SelectedPRINTList: [],
            SelectedSPOTList: [],
            addressState: true,
            bankDetailsState: true,
            EditLoad: {
                VendorTypeMediaLoaded: false,
                VendorTypeSystemLoded:false,
                VendorCodeLoaded:false
            },
            VendorSystemList: [],
            VendorMediaList: [],
            VendorCodeList: [],
        }
    },
    validations: {
        MediaVendorAmend: {
            EmployeeAgency: {
                required: required,
            },
            VendorTypeSystem: {
                required: required,
            },
            VendorTypeMedia: {
                required: requiredIf(function () {
                    return this.MediaVendorAmend.VendorTypeSystem;
                }),
            },
            VendorTypeCode: {
                required: requiredIf(function () {
                    return this.MediaVendorAmend.VendorTypeMedia;
                }),
            },
            EmployeePhoneNumber: {
                required: requiredIf(function () {
                    return this.userPhone;
                }),
                minLength: minLength(10),
                maxLength: maxLength(10),
                numeric
            },
            IsMediaRepRequest: {
                required: required,
            },
            IsBankingInfoChanged : {
                required: requiredIf(function (model) {
                    return !model.IsRemittanceNameAddressChanged;
                }),
            },
            IsRemittanceNameAddressChanged: {
                required: requiredIf(function (model) {
                    return !model.IsBankingInfoChanged;
                }),
            },
        }
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        },
    },
    components: {
        TheMask
    },
    computed: {
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        ...mapGetters('vendormediaamend', {
            MediaVendorAmend: 'MediaVendorAmend',
        }),
    },
    mounted() {
        this.getPhoneNumber();
        this.LoadAgency();
        this.GetSystemDropdown();
        this.LoadMediaSelectedValue();
    },
    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
    created() {
    },
    watch: {
        "MediaVendorAmend.IsRemittanceNameAddressChanged": function (val, NewVal) {
            if(val == false) {
                this.MediaVendorAmend.IsRemittanceNameAddressChanged=null;
            }
            if (val == null && NewVal == false) {
                this.MediaVendorAmend.VendorRemittanceName = '';
                this.MediaVendorAmend.VendorPhoneNumber = '';
                this.MediaVendorAmend.VendorFaxNumber = '';
                this.MediaVendorAmend.RemittanceCountry = '';
                this.MediaVendorAmend.StreetAddressLine1 = '';
                this.MediaVendorAmend.StreetAddressLine2 = '';
                this.MediaVendorAmend.RemittanceCity = '';
                this.MediaVendorAmend.RemittanceState = '';
                this.MediaVendorAmend.RemittanceZipCode = '';
                this.MediaVendorAmend.InvoiceAttachment = [];
            }
        },
        "MediaVendorAmend.IsBankingInfoChanged": function (val,NewVal) {
            if(val == false) {
                this.MediaVendorAmend.IsBankingInfoChanged=null;
            }
            if (val == null && NewVal == false) {
                this.MediaVendorAmend.BankName = '';
                this.MediaVendorAmend.BankCountry = '';
                this.MediaVendorAmend.BankAddressLine1 = '';
                this.MediaVendorAmend.BankAddressLine2 = '';
                this.MediaVendorAmend.BankCity = '';
                this.MediaVendorAmend.BankState = '';
                this.MediaVendorAmend.BankZipCode = '';
                this.MediaVendorAmend.AccountName = '';
                this.MediaVendorAmend.AccountNumber = '';
                this.MediaVendorAmend.RoutingNumber = '';
                this.MediaVendorAmend.BankBIC = '';
                this.MediaVendorAmend.BankIBAN = '';
                this.MediaVendorAmend.RemittanceAdviceEmail = '';
                this.MediaVendorAmend.BankLetter = [];
            }
        },
        "MediaVendorAmend.EmployeeAgency": function (val) {
            this.UpdateAgencyName(val);
        },
        "MediaVendorAmend.ID": function(val) {
            this.id = parseFloat(val);
        },
        "MediaVendorAmend.VendorTypeMedia": function ()
        {
              this.GetVendorCode();
        },
        "MediaVendorAmend.VendorTypeSystem": function () {
            this.GetMediaType();
        },
        "MediaVendorAmend.VendorTypeCode": function (newVal) {
            if (newVal)
            {
                let codeName = newVal.split("-");
                let vendorCode = codeName.shift();
                let vendorName = codeName.join("-");
                this.$set(this.MediaVendorAmend, "VendorCode", vendorCode);
                this.$set(this.MediaVendorAmend, "VendorBusinessName", vendorName);
            }
            else
            {
                this.$set(this.MediaVendorAmend, "VendorCode", '');
                this.$set(this.MediaVendorAmend, "VendorBusinessName", '');
            }
        },

    },
    methods: {
    LoadSelectedValue() {
      if (this.Edit && this.SubmittedFormData != null) {
        this.$set(this.MediaVendorAmend, "VendorTypeSystem", this.SubmittedFormData.VendorTypeMedia);
        this.$set(this.EditLoad, "EntityLoaded", true);
        if (this.ClientDetails.Entity == this.SubmittedFormData.Entity) {
          this.EntityChange();
        }
      }
    },
    LoadVendorMediaValue() {
      if (this.Edit ) {
        this.$set(
          this.MediaVendorAmend,
          "VendorTypeMedia",
          this.SubmittedFormData.VendorTypeMedia
        );
        this.$set(this.EditLoad, "VendorTypeMediaLoaded", true);
        if (
          this.MediaVendorAmend.VendorTypeMedia ==
          this.SubmittedFormData.VendorTypeMedia
        ) {
          this.GetVendorCode();
        }
      }
    },
    LoadVendorCodeNameSelectedValue() {
      if (this.Edit) {
        this.$set(
          this.MediaVendorAmend,
          "VendorTypeCode",
          this.SubmittedFormData.VendorTypeCode
        );
        this.$set(this.EditLoad, "VendorCodeLoaded", true);
      }
    },
    GetMediaType() {
      var inputObj = {
        systemCode: this.MediaVendorAmend.VendorTypeSystem,
      };
      this.$store.dispatch("vendormediaamend/GetMediaDropdown", inputObj)
        .then((res) => {
          this.VendorMediaList = res.data;
          if (this.Edit && !this.VendorTypeSystemLoded) {
            this.LoadVendorMediaValue();
          } else {
            // this.MediaVendorAmend.VendorTypeMedia = null;
            // this.MediaVendorAmend.VendorTypeCode = null;
            if (res.data.length > 0) {
              if (res.data.length == 1) {
                this.MediaVendorAmend.VendorTypeMedia = res.data[0].value;
              }
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    GetVendorCode() {
        var SystemCode = this.MediaVendorAmend.VendorTypeSystem;
      var mediaName = this.MediaVendorAmend.VendorTypeMedia;
      if (mediaName!= null) {
        var inputObj = {
          systemCode: SystemCode,
          mediaName: mediaName,
        };
          this.$store.dispatch("vendormediaamend/GetCodeDropdown", inputObj)
          .then((res) => {
          this.VendorCodeList = res.data;
          if (this.Edit) {
            this.LoadVendorCodeNameSelectedValue();
          } else {
            // this.MediaVendorAmend.VendorTypeCode = null;
            if (this.VendorCodeList.length == 1) {
              this.ClientDetails.VendorTypeCode = this.VendorCodeList[0].value;
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
      }
    },
      LoadMediaSelectedValue() {
      if (this.Edit && this.SubmittedFormData != null) {
        this.$set(this.ClientDetails, "Agency", this.SubmittedFormData.Agency);
        this.$set(this.EditLoad, "AgencyLoaded", true);
      }
    },
     SetDirty(propertyName) {
            this.$v.MediaVendorAmend[propertyName].$touch();

    },
     ResetDirty(propertyName) {
            this.$v.MediaVendorAmend[propertyName].$reset();
    },
      Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
                if (this.id) {
                this.$router.push({
                name: "Media Vendor Amend Setup Info",
                params: {
                    id: this.id,
                    isDraft: this.isDraft,
                },
                });
            } else {
                this.$router.push({
                name: "Media Vendor Amend Setup Info",
                });
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        async LoadAgency() {
            service
                .getAgencyDropdownValues("")
                .then((res) => {
                    this.AgencyList = res.data;
                    this.UpdateAgencyName(this.MediaVendorAmend.EmployeeAgency)
                })
                .catch((err) => {
                    alert(err);
                });
        },
        UpdateAgencyName(newVal) {
            const agencyValue = this.AgencyList.findIndex((item) => {
                return item.value == newVal;
            });
            if (agencyValue > -1) {
                this.MediaVendorAmend.AgencyName = this.AgencyList[agencyValue].label;
            } else {
                this.MediaVendorAmend.AgencyName = "";
            }
        },
        async GetSystemDropdown() {
            try {
                const resSystem = await this.$store.dispatch("product/GetSystemDropdown");
                if (resSystem.data) {
                    this.VendorSystemList = resSystem.data;
                }
            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
            }
        },
        async getPhoneNumber() {
            try {
                const emailAddress = await this.$store.dispatch("getEmail");
                const res = await this.$store.dispatch("product/GetReachUser", emailAddress);
                if (res.data) {
                    this.MediaVendorAmend.EmployeePhoneNumber = res.data.Phone;
                    this.userPhone = this.MediaVendorAmend.EmployeePhoneNumber.length;
                }
            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
            }
        },

    }
}
</script>

<style scoped>
.agree-checkbox .custom-control-label {
    font-weight: 400;
    font-size: 12px;
}
</style>
